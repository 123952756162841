import * as React from "react";
import {TextField} from "@mui/material";

export default function ParamField(
    props: {
        id?: string,
        disable: boolean,
        name: string,
        value: number | null,
        changeValue: (value: number) => void,
        precision: number,
        helperText: string,
        currentData: number | null,
        negativeEnabled: boolean,
    }) {
    const [lastChar, setLastChar] = React.useState<boolean>(false);
    const [prevValue, setPrevValue] = React.useState<string | null>(null);

    const step = 1 / Math.pow(10, props.precision);


    return (
        <TextField
            type="string"
            variant="filled"
            id={props.id === undefined ? props.name.toLowerCase().replace(/ /g, "_") + "_input" : props.id}
            label={props.name}
            disabled={props.disable}
            onWheel={(e: any) => {
                e.target.blur();
            }}
            inputProps={{
                step: step,
            }}
            value={
                (props.value === null || isNaN(props.value))
                    ? ""
                    : ((props.negativeEnabled && props.value === -0)
                        ? "-"
                        : (props.value === Math.floor(props.value) && props.precision > 0
                            ? (lastChar
                                ? props.value.toFixed(0) + "."
                                : props.value.toFixed(0))
                            : props.value.toFixed(props.precision)))}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                if (!props.negativeEnabled && Number.parseFloat(e.target.value) < 0) {
                    setLastChar(false);
                    props.changeValue(0);
                } else if (props.negativeEnabled && e.target.value === "-") {
                    setLastChar(false);
                    props.changeValue(-0);
                } else if (e.target.value.endsWith(".") || e.target.value.endsWith(",")) {
                    if (prevValue !== null && (e.target.value.length < prevValue.length && !prevValue?.endsWith(".0"))) {
                        setLastChar(false);
                    } else {
                        setLastChar(true);
                    }
                    props.changeValue(Number.parseFloat(e.target.value + "0"));
                } else {
                    setLastChar(false);
                    props.changeValue(Number.parseFloat(e.target.value));
                }

                setPrevValue(e.target.value);
            }}
            helperText={props.currentData === props.value || props.currentData === null ? props.helperText : props.currentData?.toFixed(props.precision) + props.helperText}>
        </TextField>

    );
}

ParamField.defaultProps = {
    precision: 0,
    id: undefined,
    helperText: "",
    currentData: null,
    negativeEnabled: false,
}