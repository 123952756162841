import * as React from "react";
import {Stack, Typography} from "@mui/material";
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import LeakRemoveIcon from '@mui/icons-material/LeakRemove';

export default function Status(props: { lastSeen: Date | null, running: boolean }) {
    return (
        <Stack direction="row" spacing={2} sx={{maxWidth: "30%"}}>
            {props.running && <LeakAddIcon color="success"/>}
            {!props.running && <LeakRemoveIcon color="error"/>}
            <Typography variant="body2">
                {props.lastSeen === null ? "Not yet connected" : "Last seen at " + props.lastSeen.toLocaleTimeString()}
            </Typography>
        </Stack>
    );
}