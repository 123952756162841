import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    InputLabel,
    Menu,
    MenuItem,
    Paper,
    Select,
    Stack,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as React from "react";
import {useState} from "react";
import {Layer, Line, Rect, Stage, Text} from "react-konva";
import Konva from "konva";
import BrickControls from "./BrickControls";
import KonvaEventObject = Konva.KonvaEventObject;


export default function BrickView(props: {
    socket: WebSocket | null,
    workingBrick: number,
    wall: Array<{ [type: string]: any }>,
}) {
    const [selectedBrick, changeSelectedBrick] = useState(0);
    const [open, openMenu] = useState<boolean>(false);
    const [menuLocation, changeMenuLocation] = useState({x: 0, y: 0});
    const [selectedSide, setSelectedSide] = useState(0);
    const [selectedRow, setSelectedRow] = useState(-1);

    const startX = 70;
    const startY = 40;
    const width = 200;
    const height = 100;
    const marginX = 70;
    const marginY = 20;
    const mortarMargin = 5;
    const mortarDetectedColor = "gray";
    const mortarDrilledColor = "green";
    const handleChange = (v: any) => {
        changeSelectedBrick(v.props.value);
    }

    const handleClick = (event: KonvaEventObject<MouseEvent>, side: number) => {
        event.evt.preventDefault();
        changeMenuLocation({x: event.evt.clientX - 2, y: event.evt.clientY - 4});
        openMenu(true);
        setSelectedSide(side);
    };
    const handleClose = () => {
        openMenu(false);
    };

    const renderBrickListItem = (e: any) => {
        return (
            <MenuItem key={e.id} value={e.id}>
                <Stack direction="row" sx={{alignItems: "center", justifyContent: "center"}} spacing={1}>
                    <Typography component="div" variant="body1"
                                sx={{fontWeight: "bold"}}>{e.id}</Typography>
                    <Stack
                        direction="column"
                        sx={{alignItems: "center", justifyContent: "center"}} spacing={1}
                    >
                        <Typography sx={{
                            maxWidth: "260px",
                            height: "auto",
                            whiteSpace: "normal"
                        }} component="p"
                                    variant="caption">{"(" + e.grid_coordinates[0] + ", " + e.grid_coordinates[1] + ")  " + e.brick_type}</Typography>
                        <Typography sx={{
                            maxWidth: "260px",
                            height: "auto",
                            whiteSpace: "normal"
                        }} component="p"
                                    variant="caption">{"W: " + e.width.toFixed(2) + ", H: " + e.height.toFixed(2)}</Typography>
                    </Stack>
                </Stack>
            </MenuItem>);
    }

    const renderRowListItem = (e: Array<number>) => {
        let defaultContent = (
            <Stack direction="row" sx={{alignItems: "center", justifyContent: "center"}} spacing={1}>
                <Typography component="div" variant="body1"
                            sx={{fontWeight: "bold"}}>{e[0]}</Typography>
                <Typography sx={{
                    maxWidth: "260px",
                    height: "auto",
                    whiteSpace: "normal"
                }} component="p"
                            variant="caption">{e[1] + " bricks"}</Typography>
            </Stack>
        );

        if (e[0] === -1) {
            defaultContent = (
                <Stack direction="row" sx={{alignItems: "center", justifyContent: "center"}} spacing={2}>
                    <Typography component="div" variant="body1"
                                sx={{fontWeight: "bold"}}>{"All rows"}</Typography>
                    <Typography sx={{
                        maxWidth: "260px",
                        height: "auto",
                        whiteSpace: "normal"
                    }} component="p"
                                variant="caption">{"All bricks"}</Typography>
                </Stack>
            );
        }
        return (
            <MenuItem key={e[0]} value={e[0]}>
                {defaultContent}
            </MenuItem>
        );
    }

    const renderBrick = (e: any) => {
        if (e === null || e === undefined) return (
            <Typography>Select a valid brick</Typography>
        );

        return (
            <Stage width={width + 2 * startX} height={height + 2 * startY}>
                <Layer>
                    <Rect
                        x={startX}
                        y={startY}
                        width={width}
                        height={height}
                        fill='#BC4A3C'
                        shadowBlur={5}
                    />
                    <Text
                        x={startX - marginX}
                        y={startY - marginY}
                        fontSize={11}
                        text={"X:" + e.position[4].toFixed(2) + "\nY:" + e.position[5].toFixed(2) + "\ndepth:" + e.depth[1].toFixed(2)}/>
                    <Text
                        x={startX + width + 3}
                        y={startY - marginY}
                        fontSize={11}
                        text={"X:" + e.position[2].toFixed(2) + "\nY:" + e.position[3].toFixed(2) + "\ndepth:" + e.depth[0].toFixed(2)}/>
                    <Text
                        x={startX - marginX}
                        y={startY + height - marginY}
                        fontSize={11}
                        text={"X:" + e.position[6].toFixed(2) + "\nY:" + e.position[7].toFixed(2) + "\ndepth:" + e.depth[2].toFixed(2)}/>
                    <Text
                        x={startX + width + 3}
                        y={startY + height - marginY}
                        fontSize={11}
                        text={"X:" + e.position[8].toFixed(2) + "\nY:" + e.position[9].toFixed(2) + "\ndepth:" + e.depth[3].toFixed(2)}/>
                    <Text
                        x={startX + width / 2 - marginX / 2}
                        y={startY + height / 2 - marginY / 2}
                        fontSize={11}
                        text={"X:" + e.position[8].toFixed(2) + "\nY:" + e.position[9].toFixed(2) + "\ndepth:" + e.depth[3].toFixed(2)}
                        fill="#ffffff"/>
                    <Line
                        points={[startX - mortarMargin, startY - mortarMargin, startX + width + mortarMargin, startY - mortarMargin]}
                        stroke={e.mortar_removed[0] ? mortarDrilledColor : mortarDetectedColor}
                        strokeWidth={e.mortar_detected[0] ? 4 : 0}
                        onClick={(t) => handleClick(t, 0)}
                    />
                    <Line
                        points={[startX - mortarMargin, startY - mortarMargin, startX - mortarMargin, startY + height + mortarMargin]}
                        stroke={e.mortar_removed[1] ? mortarDrilledColor : mortarDetectedColor}
                        strokeWidth={e.mortar_detected[1] ? 4 : 0}
                        onClick={(t) => handleClick(t, 1)}
                    />
                    <Line
                        points={[startX - mortarMargin, startY + height + mortarMargin, startX + width + mortarMargin, startY + height + mortarMargin]}
                        stroke={e.mortar_removed[2] ? mortarDrilledColor : mortarDetectedColor}
                        strokeWidth={e.mortar_detected[2] ? 4 : 0}
                        onClick={(t) => handleClick(t, 2)}
                    />
                    <Line
                        points={[startX + width + mortarMargin, startY + height + mortarMargin, startX + width + mortarMargin, startY - mortarMargin]}
                        stroke={e.mortar_removed[3] ? mortarDrilledColor : mortarDetectedColor}
                        strokeWidth={e.mortar_detected[3] ? 4 : 0}
                        onClick={(t) => handleClick(t, 3)}
                    />
                </Layer>
            </Stage>
        );
    }

    const renderContent = (wall: any) => {
        if (wall !== null && wall.length > 0 && wall.length > 0) {
            const brickData = wall.filter((o: any) => o.id === selectedBrick)[0];
            let brickText;
            if (brickData === undefined || brickData === null) {
                brickText = (<Typography>Select a valid brick</Typography>);
            } else {
                brickText = (
                    <Stack direction="column" spacing={1} sx={{maxWidth: "35%"}}>
                        <Typography
                            variant="body1"
                        >
                            {"Brick ID: " + brickData.id}
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            {"Data size: " + brickData.data_size}
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            {"First found: [" + brickData.found_loops.slice(0, 3) + "]"}
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            {"Last found: [" + brickData.found_loops.slice(-3) + "]"}
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            {"Working brick: " + props.workingBrick}
                        </Typography>
                    </Stack>
                )
            }


            const numbers = wall.map((o: any) => o.grid_coordinates[1]);
            const uniqueNumbers = numbers.filter((value: any, index: any, self: any) => self.indexOf(value) === index);

            let rows = uniqueNumbers.reduce((acc: any, num: number) => {
                const frequency = numbers.filter((x: any) => x === num).length;
                acc.push([num, frequency]);
                return acc;
            }, []);

            rows = [[-1, -1], ...rows]

            return (<Stack direction="column" spacing={1} sx={{
                justifyContent: "center",
                alignItems: "center",
            }}>

                <Stack direction="row" spacing={2} sx={{justifyContent: "center", alignItems: "center"}}>
                    {brickText}
                    <FormControl sx={{maxWidth: "35%"}}>
                        <InputLabel id="brick-select-label">Brick</InputLabel>
                        <Select
                            labelId="brick-select-label"
                            id="brick-select"
                            value={selectedBrick}
                            label="Brick"
                            onChange={(e, v) => handleChange(v)}
                        >
                            {wall.filter((o: any) => selectedRow === -1 || o.grid_coordinates[1] === selectedRow).sort(
                                (a: any, b: any) => b.id - a.id).map((o: any) => renderBrickListItem(o))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{maxWidth: "30%"}}>
                        <InputLabel id="row-select-label">Row</InputLabel>
                        <Select
                            labelId="row-select-label"
                            id="row-select"
                            value={selectedRow}
                            label="Row"
                            onChange={(e, v: any) => setSelectedRow(v.props.value)}
                        >
                            {rows.map((o: any) => renderRowListItem(o))}
                        </Select>
                    </FormControl>
                </Stack>

                {
                    renderBrick(brickData)
                }
                {
                    renderMenu(brickData, selectedSide)
                }
                <BrickControls socket={props.socket} currentBrick={selectedBrick}/>
            </Stack>)

        } else {
            return (
                <Typography>
                    No wall data available
                </Typography>
            );
        }
    }

    const undrill = (brickId: number, sideIndex: number) => {
        if (props.socket === null) {
            return;
        }

        const params = {
            id: brickId,
            side: sideIndex
        }

        const command = {
            method: "command",
            data: {
                command: "undrill",
                params: params
            }
        }

        props.socket.send(JSON.stringify(command));
        handleClose()
    }

    const drill = (brickId: number, sideIndex: number) => {
        if (props.socket === null) {
            return;
        }

        const params = {
            id: brickId,
            side: sideIndex
        }

        const command = {
            method: "command",
            data: {
                command: "drill",
                params: params
            }
        }

        props.socket.send(JSON.stringify(command));
        handleClose()
    }

    const removeMortar = (brickId: number, sideIndex: number) => {
        if (props.socket === null) {
            return;
        }

        const params = {
            id: brickId,
            side: sideIndex
        }

        let command = {
            method: "command",
            data: {
                command: "remove_mortar",
                params: params
            }
        }

        props.socket.send(JSON.stringify(command));
        handleClose()
    }
    const renderMenu = (brickData: any, sideIndex: number) => {
        if (brickData === null || brickData === undefined) {
            return null;
        }
        let secondOption;
        if (brickData.mortar_removed[sideIndex]) {
            secondOption = <MenuItem onClick={() => undrill(brickData.id, sideIndex)}>Undrill</MenuItem>
        } else {
            secondOption = <MenuItem onClick={() => drill(brickData.id, sideIndex)}>Drill</MenuItem>
        }
        return (
            <Menu
                id="mortar-menu"
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorReference="anchorPosition"
                anchorPosition={{left: menuLocation.x, top: menuLocation.y}}
            >
                <MenuItem onClick={() => removeMortar(brickData.id, sideIndex)}>Remove mortar</MenuItem>
                {secondOption}
            </Menu>
        );
    }

    return (
        <Paper elevation={4} sx={{width: "95%"}}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="run-bricks-content"
                    id="run-bricks-header"
                >
                    <Typography variant="h6">Brick control</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {renderContent(props.wall)}
                </AccordionDetails>
            </Accordion>
        </Paper>
    );
}