import {Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField} from "@mui/material";
import React, {useState} from "react";
import ExposureIcon from '@mui/icons-material/Exposure';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenWithIcon from '@mui/icons-material/OpenWith';


export default function BrickControls(props: { socket: WebSocket | null, currentBrick: number }) {
    const [distance, setDistance] = useState(0);
    const [side, setSide] = useState(0);

    const [x, setX] = useState(0);
    const [y, setY] = useState(0);

    const [angle, setAngle] = useState(0);

    return (
        <Stack direction="column" spacing={2} margin={3}>
            <Stack direction="row" spacing={2} sx={{width: "100%", height: "100%"}}>
                <TextField
                    id="extend_side"
                    type="number"
                    onWheel={(e: any) => {
                        e.target.blur();
                    }}
                    label="Distance"
                    sx={{width: "30%"}}
                    value={distance}
                    onChange={(event) => setDistance(parseInt(event.target.value))}
                >
                </TextField>
                <FormControl sx={{width: "30%"}}>
                    <InputLabel id="side-select-label">Side</InputLabel>
                    <Select
                        labelId="side-select-label"
                        id="side-select"
                        label="Side"
                        value={side.toString()}
                        onChange={(event: SelectChangeEvent) => setSide(parseInt(event.target.value))}
                    >
                        <MenuItem key={0} value="0">
                            Top
                        </MenuItem>
                        <MenuItem key={1} value="1">
                            Left
                        </MenuItem>
                        <MenuItem key={2} value="2">
                            Bottom
                        </MenuItem>
                        <MenuItem key={3} value="3">
                            Right
                        </MenuItem>
                    </Select>
                </FormControl>

                <Button endIcon={<ExposureIcon/>} sx={{width: "30%"}} onClick={() => {
                    const command = {
                        method: "command",
                        data: {
                            command: "extend_side",
                            params: {
                                id: props.currentBrick,
                                side: side,
                                length: distance
                            }
                        }
                    }
                    if (props.socket !== null) {
                        props.socket.send(JSON.stringify(command));
                    }
                }}>
                    Extend
                </Button>
            </Stack>
            <Stack direction="row" spacing={2}>
                <TextField
                    id="move_brick_x"
                    type="number"
                    onWheel={(e: any) => {
                        e.target.blur();
                    }}
                    label="X"
                    sx={{width: "30%"}}
                    value={x}
                    onChange={(event) => setX(parseInt(event.target.value))}
                />
                <TextField
                    id="move_brick_y"
                    type="number"
                    onWheel={(e: any) => {
                        e.target.blur();
                    }}
                    label="Y"
                    sx={{width: "30%"}}
                    value={y}
                    onChange={(event) => setY(parseInt(event.target.value))}
                />
                <Button endIcon={<OpenWithIcon/>} sx={{width: "30%"}}
                        onClick={() => {
                            const command = {
                                method: "command",
                                data: {
                                    command: "move_brick",
                                    params: {
                                        id: props.currentBrick,
                                        x: x,
                                        y: y
                                    }
                                }
                            }
                            if (props.socket !== null) {
                                props.socket.send(JSON.stringify(command));
                            }
                        }
                        }
                >
                    Move
                </Button>
            </Stack>
            <Stack direction="row" spacing={2}>
                <TextField
                    id="rotate_brick"
                    type="number"
                    onWheel={(e: any) => {
                        e.target.blur();
                    }}
                    inputProps={{step: 0.1}}
                    value={angle.toFixed(1)}
                    label="Angle"
                    sx={{width: "50%"}}
                    onChange={(event) => setAngle(parseFloat(event.target.value))}
                    helperText="(Minus-↻, Plus-↺)"
                />
                <Button endIcon={<RotateLeftIcon/>} sx={{width: "50%"}}
                        onClick={() => {
                            const command = {
                                method: "command",
                                data: {
                                    command: "rotate_brick",
                                    params: {
                                        id: props.currentBrick,
                                        angle: angle
                                    }
                                }
                            }
                            if (props.socket !== null) {
                                props.socket.send(JSON.stringify(command));
                            }
                        }}
                >
                    Rotate
                </Button>
            </Stack>
            <Stack direction="row" spacing={2}>
                <Button endIcon={<FileCopyIcon/>} sx={{width: "50%"}}
                        onClick={() => {
                            const command = {
                                method: "command",
                                data: {
                                    command: "copy_brick",
                                    params: {
                                        id: props.currentBrick
                                    }
                                }
                            }
                            if (props.socket !== null) {
                                props.socket.send(JSON.stringify(command));
                            }
                        }}
                >
                    Copy
                </Button>
                <Button endIcon={<DeleteIcon/>} sx={{width: "50%"}}
                        onClick={() => {
                            const command = {
                                method: "command",
                                data: {
                                    command: "delete_brick",
                                    params: {
                                        id: props.currentBrick
                                    }
                                }
                            }
                            if (props.socket !== null) {
                                props.socket.send(JSON.stringify(command));
                            }
                        }}
                >
                    Delete
                </Button>
            </Stack>
        </Stack>
    );
}