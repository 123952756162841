import * as React from "react";
import {Button, Paper, Stack} from "@mui/material";
import StartIcon from '@mui/icons-material/Start';
import PauseIcon from '@mui/icons-material/Pause';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import CloseIcon from '@mui/icons-material/Close';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

export default function ExecutionButtonGroup(props: {
    execution: "stopped" | "running" | "paused",
    socket: WebSocket | null
}) {
    const buttons = ["Calibrate", "Start", "Pause", "Quick Pause", "Resume", "Skip", "Move out", "Stop"];
    const enabledButtons = {
        "stopped": ["Calibrate", "Start"],
        "running": ["Pause", "Quick Pause"],
        "paused": ["Resume", "Stop", "Skip", "Move out"]
    };

    const icons: { [name: string]: any } = {
        "Calibrate": <DesignServicesIcon/>,
        "Start": <StartIcon/>,
        "Pause": <PauseIcon/>,
        "Quick Pause": <PriorityHighIcon/>,
        "Resume": <PlayArrowIcon/>,
        "Stop": <CloseIcon/>,
        "Skip": <SkipNextIcon/>,
        "Move out": <ZoomOutMapIcon/>
    };

    const calls: { [name: string]: { command: string, params: { [key: string]: any } } } = {
        "Calibrate": {command: "start", params: {autocalibration: 1}},
        "Start": {command: "start", params: {autocalibration: 0}},
        "Pause": {command: "pause", params: {quick: 0}},
        "Quick Pause": {command: "pause", params: {quick: 1}},
        "Resume": {command: "resume", params: {}},
        "Stop": {command: "stop", params: {}},
        "Skip": {command: "skip", params: {}},
        "Move out": {command: "move_out", params: {}}
    };

    const performCall = (name: string) => {
        let message = {
            method: "command",
            data: calls[name]
        }

        if (props.socket !== null && props.socket.OPEN) {
            props.socket.send(JSON.stringify(message));
        }
    };

    const renderButton = (name: string) => {
        const butDisabled = enabledButtons[props.execution].indexOf(name) === -1;
        return (
            <Paper elevation={butDisabled ? 1 : 8} key={name}>
                <Button
                    endIcon={icons[name]}
                    sx={{width: "100%", height: "59px"}}
                    disabled={butDisabled}
                    onClick={() => performCall(name)}>
                    {name}
                </Button>
            </Paper>);
    }

    return (
        <Stack direction="column" spacing={4} sx={{maxWidth: "18%", margin: 2}}>
            {buttons.map(n => renderButton(n))}
        </Stack>
    );

}