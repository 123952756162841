import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import * as React from "react";
import {useState} from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {checkFloat, strToColor} from "../../utils/utils";
import {ResponsiveLine} from "@nivo/line";


export default function DataDisplay(props: {
    data: {
        [datetime: number]: { datetime: number, stopped: number, data: any }
    }, index: number | null
}) {
    const [graphEnabled, toggleGraph] = useState<Array<string>>([]);
    const [pinned, togglePinned] = useState<Array<string>>(["drilling_initial_speed_now", "drilling_wayback_speed_now", "power_usage_spindle"]);
    const [searchText, changeSearch] = useState("");
    const transformDataLine = (param: string) => {
        if (props.index === null) return [];

        let single: { id: string, color: string, data: any };
        let singleData = [];
        const dataOnly = []
        for (let idx in props.data) {
            dataOnly.push(props.data[idx].data);
        }

        let min = Math.min.apply(null, dataOnly.map(e => e[param]));
        let max = Math.max.apply(null, dataOnly.map(e => e[param]));
        for (let idx in dataOnly) {
            singleData.push({
                "x": idx,
                "y": dataOnly[idx][param]
            });
        }
        single = {"id": param, "color": strToColor(param), "data": singleData};
        return [single, {
            "id": "now",
            color: "red",
            "data": [{"x": Object.keys(props.data).indexOf(props.index.toString()), "y": min}, {
                "x": Object.keys(props.data).indexOf(props.index.toString()),
                "y": max
            }]
        }];
    }
    const renderDataRow = (param: string, value: number | null) => {
        return (
            <Stack direction="column" sx={{width: "100%", alignItems: "center"}} key={param}>
                <Stack direction="row" sx={{width: "100%", alignItems: "center"}}>
                    <Typography variant="body1"
                                sx={{textAlign: "right", width: "40%", marginRight: 2}}>{param}:</Typography>
                    <Typography
                        variant="body1">{value === null ? "-" : (checkFloat(value) ? value.toFixed(2) : value)}</Typography>
                    <Checkbox
                        sx={{marginLeft: "auto"}}
                        checked={pinned.indexOf(param) !== -1}
                        onChange={() => {
                            if (pinned.indexOf(param) === -1) {
                                togglePinned([...pinned, param]);
                            } else {
                                togglePinned([...pinned.slice(0, pinned.indexOf(param)), ...pinned.slice(pinned.indexOf(param) + 1)]);
                            }
                        }}/><Typography variant="caption">Pin</Typography>
                    <Checkbox
                        checked={graphEnabled.indexOf(param) !== -1}
                        onChange={() => {
                            if (graphEnabled.indexOf(param) === -1) {
                                toggleGraph([...graphEnabled, param]);
                            } else {
                                toggleGraph([...graphEnabled.slice(0, graphEnabled.indexOf(param)), ...graphEnabled.slice(graphEnabled.indexOf(param) + 1)]);
                            }
                        }}/><Typography variant="caption">Graph</Typography>
                </Stack>
                {graphEnabled.indexOf(param) !== -1 && <Box sx={{width: "100%", height: "150px"}}>
                    <ResponsiveLine
                        data={transformDataLine(param)}
                        margin={{top: 30, right: 30, bottom: 30, left: 30}}
                        xScale={{type: 'point'}}
                        yScale={{
                            type: 'linear',
                            min: 'auto',
                            max: 'auto',
                            reverse: false,
                            stacked: false
                        }}
                        yFormat=" >-.4"
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 1,
                            tickPadding: 3,
                            tickRotation: 0,
                            legend: 'Time',
                            legendOffset: 25,
                            legendPosition: 'middle'
                        }}
                        axisLeft={{
                            tickSize: 1,
                            tickPadding: 3,
                            tickRotation: 0,
                            legend: 'Values',
                            legendOffset: -25,
                            legendPosition: 'middle'
                        }}
                        pointSize={4}
                        pointColor={{theme: 'background'}}
                        pointBorderWidth={2}
                        pointBorderColor={{from: 'serieColor'}}
                        pointLabelYOffset={-12}
                        useMesh={true}
                    />
                </Box>}
            </Stack>);
    }

    const renderData = (data: { [datetime: number]: { datetime: number, stopped: number, data: any } }) => {
        if (props.index !== null && data !== null && Object.keys(data).length > 0 && data[props.index].data !== null) {
            return (
                <Stack direction={"column"}>
                    {Object.keys(data[props.index].data).filter(e => e.indexOf(searchText) !== -1).sort((a, b) => {
                        if (pinned.indexOf(a) !== -1 && pinned.indexOf(b) === -1) {
                            return -Math.pow(10, 6);
                        }

                        if (pinned.indexOf(b) !== -1 && pinned.indexOf(a) === -1) {
                            return Math.pow(10, 6);
                        }

                        return a.localeCompare(b);
                    }).map((e: string) => props.index === null ? null : renderDataRow(e, data[props.index].data[e]))}
                </Stack>);
        } else {
            return <Typography variant="body1">No data available</Typography>;
        }
    }

    return (
        <Paper elevation={4} sx={{width: "95%"}}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="run-param-content"
                    id="run-param-header"
                >
                    <Typography variant="h6">Data display</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextField value={searchText}
                               sx={{width: "100%"}}
                               id="param_search"
                               label="Search params..."
                               onChange={e => changeSearch(e.target.value)}/>
                    {renderData(props.data)}
                </AccordionDetails>
            </Accordion>
        </Paper>
    );
}