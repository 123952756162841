import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import noImage from "../../images/no-image.png"
import DetailedImage from "./DetailedImage";

export default function ImageGroup(props: {
    socket: WebSocket | null,
    enableManualBricks: boolean,
    availableImages: { [type: string]: string } | undefined,
    availableBricks: string[] | undefined
}) {
    const [displayingType, changeDisplayType] = useState("annotated")

    useEffect(() => {
        if (props.availableImages) {
            for (let type in props.availableImages) {
                const img = new Image();
                img.src = props.availableImages[type];
            }
        }
    }, [props.availableImages]);

    const renderBottomRow = (type: string) => {
        const widthString = props.availableImages === undefined ? "0%" : 100 / (Object.keys(props.availableImages).length - 1) + "%";

        return (
            <Box
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    width: widthString,
                    height: "100%",
                }}
                key={type}
            >
                <img style={{width: '100%', height: '100%', objectFit: 'contain'}} loading="eager" alt={type} key={type}
                     src={props.availableImages === undefined ? noImage : props.availableImages[type]}/>
                <Button
                    onClick={() => changeDisplayType(type)}
                    sx={{
                        minWidth: "100%",
                        width: "100%",
                        height: "100%",
                        margin: 0,
                        padding: 0,
                        position: "absolute",
                        color: 'blue',
                        top: 0,
                        left: 0
                    }}>{type}</Button>
            </Box>)
    }

    const sendRefreshRequest = () => {
        if (props.socket === null) {
            return;
        }
        let command = {
            method: "command",
            data: {
                command: "refresh_images",
                params: {}
            }
        }
        props.socket.send(JSON.stringify(command));
    }

    return (
        <Stack direction="column"
               sx={{
                   width: "100%",
                   height: "95%",
                   margin: 1,
                   justifyContent: "center",
                   alignItems: "center"
               }}>
            <Box sx={{
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                height: "70%",
                width: "100%",
                margin: 1,
            }}>
                <DetailedImage
                    socket={props.socket}
                    availableBricks={props.availableBricks}
                    enableManualBricks={props.enableManualBricks}
                    src={props.availableImages === undefined || Object.keys(props.availableImages).length === 0 ? noImage : props.availableImages[displayingType]}/>
                <Button
                    variant="outlined"
                    onClick={() => sendRefreshRequest()}
                    sx={{
                        margin: "1%",
                        width: "98%",
                        height: "8%",
                    }}>
                    {displayingType.toUpperCase()}
                </Button>
            </Box>
            <Stack direction="row"
                   flexGrow={1}
                   sx={{
                       justifyContent: "center",
                       alignItems: "center",
                       position: "relative",
                       height: "30%",
                       width: "100%",
                   }}>
                {props.availableImages !== undefined && Object.keys(props.availableImages).filter(e => e !== displayingType).map(e => renderBottomRow(e))}
                {props.availableImages === undefined &&
                    <Typography align="center" variant="body1" component="span" sx={{
                        width: "100%",
                    }}>No images available</Typography>}
            </Stack>
        </Stack>
    );
}