import React, {useEffect, useMemo, useState} from "react";
import {Menu, MenuItem} from "@mui/material";
import SubMenu from "./SubMenu";

interface DetailedImageProps {
    socket: WebSocket | null;
    src: string;
    enableManualBricks: boolean;
    availableBricks: Array<string> | undefined;
}

const containerStyle: React.CSSProperties = {
    position: "relative",
    width: "100%",
    height: "90%",
    overflow: "hidden",
};

const imageStyle: React.CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "contain",
};

const zoomedImageStyle: React.CSSProperties = {
    ...imageStyle,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    cursor: "crosshair",
};

const DetailedImage: React.FC<DetailedImageProps> = ({socket, src, enableManualBricks, availableBricks}) => {
    const [isZoomed, setIsZoomed] = useState(false);
    const [zoomedImageSrc, setZoomedImageSrc] = useState("");
    const [drawingMode, setDrawingMode] = useState(false);
    const [middleSaved, setMiddleSaved] = useState(false);
    const [middleSaved2, setMiddleSaved2] = useState(false);
    const [startPoint, setStartPoint] = useState({x: 0, y: 0});
    const [middlePoint, setMiddlePoint] = useState({x: 0, y: 0});
    const [middlePoint2, setMiddlePoint2] = useState({x: 0, y: 0});
    const [endPoint, setEndPoint] = useState({x: 0, y: 0});
    const [zoomedImagePosition, setZoomedImagePosition] = useState({x: 0, y: 0});
    const [showMenu, setShowMenu] = useState(false);
    const [menuPosition, setMenuPosition] = useState({x: 0, y: 0});

    const zoom = 1.9;

    const containerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const img = new Image();
        img.src = src;
    }, [src]);

    React.useEffect(() => {
        const handleContextMenu = (event: MouseEvent) => {
            event.preventDefault();
            if (drawingMode) {
                resetDrawing();
            }
        };

        const containerRefCopy = containerRef.current; // Copy the ref value to a variable

        if (containerRefCopy) {
            containerRefCopy.addEventListener("contextmenu", handleContextMenu);
        }

        return () => {
            if (containerRefCopy) {
                containerRefCopy.removeEventListener("contextmenu", handleContextMenu);
            }
        };
    }, [drawingMode]);

    const resetDrawing = () => {
        setDrawingMode(false);
        setMiddleSaved(false);
        setMiddlePoint({x: 0, y: 0});
        setMiddlePoint2({x: 0, y: 0});
        setStartPoint({x: 0, y: 0});
        setEndPoint({x: 0, y: 0});
    }

    const handleMouseToggle = (enter: boolean) => {
        if (!enableManualBricks || showMenu) {
            return;
        }

        if (!drawingMode) {
            setIsZoomed(enter);
        }
    }

    const calculateZoomedImage = (x: number, y: number, imgWidth: number, imgHeight: number, containerWidth: number, containerHeight: number, zoom: number) => {
        const zoomWidth = containerWidth / zoom;
        const zoomHeight = containerHeight / zoom;

        const zoomX = Math.min(Math.max(x * imgWidth - zoomWidth / 2, 0), imgWidth - zoomWidth);
        const zoomY = Math.min(Math.max(y * imgHeight - zoomHeight / 2, 0), imgHeight - zoomHeight);

        const zoomedImagePosition = {x: zoomX, y: imgHeight - zoomHeight - zoomY};

        const absoluteX = Math.round(zoomX + x * zoomWidth);
        const absoluteY = imgHeight - Math.round(zoomY + y * zoomHeight);

        return {zoomedImagePosition, absoluteX, absoluteY};
    };

    const handleMouseEvent = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!enableManualBricks || showMenu) {
            return;
        }

        const {left, top, width, height} = event.currentTarget.getBoundingClientRect();
        const x = (event.clientX - left) / width;
        const y = (event.clientY - top) / height;

        // Calculate the zoomed image's dimensions and position
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => {
            const containerWidth = containerRef.current?.offsetWidth ?? 0;
            const containerHeight = containerRef.current?.offsetHeight ?? 0;
            const imgWidth = img.width;
            const imgHeight = img.height;

            const {
                zoomedImagePosition,
                absoluteX,
                absoluteY
            } = calculateZoomedImage(x, y, imgWidth, imgHeight, containerWidth, containerHeight, zoom);
            setZoomedImagePosition(zoomedImagePosition);

            if (event.type === 'mousedown') {

                if (event.button === 0 && isZoomed && !drawingMode && containerRef.current) {
                    setStartPoint({x: absoluteX, y: absoluteY});
                    setEndPoint({x: absoluteX, y: absoluteY});
                    setMiddlePoint({x: absoluteX, y: absoluteY});
                    setMiddlePoint2({x: absoluteX, y: absoluteY});
                    setMiddleSaved(false);
                    setMiddleSaved2(false);
                    setDrawingMode(true);
                } else if (event.button === 0 && isZoomed && drawingMode && containerRef.current) {
                    if (!middleSaved) {
                        setMiddlePoint({x: absoluteX, y: absoluteY});
                        setMiddlePoint2({x: absoluteX, y: absoluteY});
                        setEndPoint({x: absoluteX, y: absoluteY});
                        setMiddleSaved(true);
                    } else if (!middleSaved2) {
                        setMiddlePoint2({x: absoluteX, y: absoluteY});
                        setEndPoint({x: absoluteX, y: absoluteY});
                        setMiddleSaved2(true);
                    } else {
                        setEndPoint({x: absoluteX, y: absoluteY});
                        setDrawingMode(false);
                        setMenuPosition({x: event.clientX - 2, y: event.clientY - 4});
                        setShowMenu(true);
                        setIsZoomed(false);
                    }
                } else if (event.button === 2 && drawingMode) {
                    resetDrawing();
                }
            } else if (event.type === 'mousemove') {
                if (drawingMode) {
                    if (middleSaved2) {
                        setEndPoint({x: absoluteX, y: absoluteY});
                    } else if (middleSaved) {
                        setMiddlePoint2({x: absoluteX, y: absoluteY});
                        setEndPoint({x: absoluteX, y: absoluteY});
                    } else {
                        setMiddlePoint({x: absoluteX, y: absoluteY});
                        setMiddlePoint2({x: absoluteX, y: absoluteY});
                        setEndPoint({x: absoluteX, y: absoluteY});
                    }
                }

                const canvas = document.createElement("canvas");
                canvas.width = containerWidth / zoom;
                canvas.height = containerHeight / zoom;
                const ctx = canvas.getContext("2d");
                if (ctx) {
                    ctx.drawImage(img, zoomedImagePosition.x, imgHeight - zoomedImagePosition.y - containerHeight / zoom, containerWidth / zoom, containerHeight / zoom, 0, 0, containerWidth / zoom, containerHeight / zoom);
                    setZoomedImageSrc(canvas.toDataURL());
                }
            }
        };
        img.src = src;
    };

    const getLine = (start: { x: number, y: number }, end: { x: number, y: number }, color: string) => {
        const line = document.createElementNS("http://www.w3.org/2000/svg", "line");
        line.setAttribute("x1", start.x.toString());
        line.setAttribute("y1", start.y.toString());
        line.setAttribute("x2", end.x.toString());
        line.setAttribute("y2", end.y.toString());
        line.setAttribute("stroke", color);
        line.setAttribute("stroke-width", "2");
        return line;
    }

    const getPoint = (point: { x: number, y: number }, color: string, radius: number) => {
        const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        circle.setAttribute("cx", point.x.toString());
        circle.setAttribute("cy", point.y.toString());
        circle.setAttribute("r", radius.toString());
        circle.setAttribute("fill", color);
        return circle;
    }

    const svgElement = useMemo(
        () => {
            const renderSvg = (startPoint: any, middlePoint: any, middlePoint2: any, endPoint: any, isZoomed: boolean) => {
                const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                svg.setAttribute("width", "100%");
                svg.setAttribute("height", "100%");
                svg.style.position = "absolute";
                svg.style.top = "0";
                svg.style.left = "0";
                svg.style.pointerEvents = "none";

                let start: any = null, middle: any = null, middle2: any = null, end: any = null;
                const img = new Image();
                img.crossOrigin = "anonymous";
                img.onload = () => {
                    const imgWidth = img.width;
                    const imgHeight = img.height;

                    const containerWidth = containerRef.current?.offsetWidth ?? 0;
                    const containerHeight = containerRef.current?.offsetHeight ?? 0;

                    if (!isZoomed) {
                        let scaleX = containerWidth / imgWidth;
                        let scaleY = containerHeight / imgHeight;

                        const containerRatio = containerWidth / containerHeight;
                        const imageRatio = imgWidth / imgHeight;
                        let offsetX: number = 0, offsetY: number = 0;

                        if (containerRatio > imageRatio) {
                            scaleX = scaleY = containerHeight / imgHeight;
                            offsetX = (containerWidth - imgWidth * scaleY) / 2;
                        } else {
                            scaleX = scaleY = containerWidth / imgWidth;
                            offsetY = (containerHeight - imgHeight * scaleX) / 2;
                        }

                        // Convert absolute coordinates to container coordinates
                        const convertCoordinates = (point: { x: number; y: number }) => ({
                            x: point.x * scaleX + offsetX,
                            y: containerHeight - point.y * scaleY - offsetY,
                        });

                        // Draw lines and points
                        start = convertCoordinates(startPoint)
                        middle = convertCoordinates(middlePoint)
                        middle2 = convertCoordinates(middlePoint2)
                        end = convertCoordinates(endPoint)
                    } else {
                        const zoomWidth = containerWidth / zoom;
                        const zoomHeight = containerHeight / zoom;

                        const convertCoordinates = (point: { x: number; y: number }) => {
                            const x = (point.x - zoomedImagePosition.x) * containerWidth / zoomWidth;
                            const y = containerHeight - ((point.y - zoomedImagePosition.y) * containerHeight / zoomHeight);
                            return {x, y};
                        };

                        start = convertCoordinates(startPoint);
                        middle = convertCoordinates(middlePoint);
                        middle2 = convertCoordinates(middlePoint2);
                        end = convertCoordinates(endPoint);
                    }

                    if (start !== null && middle !== null && end !== null) {
                        svg.appendChild(getLine(start, middle, "red"));
                        svg.appendChild(getLine(middle, middle2, "red"));
                        svg.appendChild(getLine(middle2, end, "red"));

                        [start, middle, middle2, end].forEach((point, index) => {
                            svg.appendChild(getPoint(point, "blue", 3));
                        });
                    }
                };
                img.src = src;

                return svg;
            }

            return renderSvg(startPoint, middlePoint, middlePoint2, endPoint, isZoomed);
        },
        [startPoint, middlePoint, middlePoint2, endPoint, isZoomed, src, zoomedImagePosition.x, zoomedImagePosition.y]
    );

    const svgElementRef = React.useRef<SVGSVGElement | null>(null);

    React.useEffect(() => {
        const currentSvgElement = svgElementRef.current;
        if (currentSvgElement) {
            while (currentSvgElement.firstChild) {
                currentSvgElement.removeChild(currentSvgElement.firstChild);
            }
            currentSvgElement.appendChild(svgElement);
        }
    }, [svgElement]);


    const handleClose = () => {
        setShowMenu(false);
        resetDrawing();
    };

    const submitManualBrick = (id: string | null) => {
        handleClose();
        if (socket === null) {
            return;
        }

        let command = {
            method: "command",
            data: {
                command: "add_brick",
                params: {
                    id: id,
                    points: [startPoint, middlePoint, middlePoint2, endPoint]
                }
            }
        }
        socket.send(JSON.stringify(command));
    }

    useEffect(() => {
        if (!enableManualBricks) {
            setIsZoomed(false);
            resetDrawing();
        }
    }, [enableManualBricks]);


    return (
        <div
            style={containerStyle}
            onMouseEnter={() => handleMouseToggle(true)}
            onMouseMove={handleMouseEvent}
            onMouseLeave={() => handleMouseToggle(false)}
            onMouseDown={handleMouseEvent}
            ref={containerRef}
        >
            <img src={src} alt="" loading="eager" style={imageStyle}/>
            {isZoomed && <img src={zoomedImageSrc} loading="eager" alt="" style={zoomedImageStyle}/>}
            {enableManualBricks && <div ref={svgElementRef as any}/>}
            <Menu
                id="add-brick-menu"
                open={showMenu}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorReference="anchorPosition"
                anchorPosition={{left: menuPosition.x, top: menuPosition.y}}
            >
                <MenuItem onClick={() => submitManualBrick(null)}>Add new</MenuItem>
                {availableBricks !== undefined && <SubMenu text="Set position" options={availableBricks.reverse()}
                                                           selectFunction={(a: string) => submitManualBrick(a)}/>}
            </Menu>
        </div>
    );
};

export default DetailedImage;