import {Accordion, AccordionDetails, AccordionSummary, Button, Grid, Paper, Typography,} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RestorePageIcon from '@mui/icons-material/RestorePage';
import ParamField from "./ParamField";


export default function MillingSetup(props: {
    socket: WebSocket | null,
    running: "stopped" | "running" | "paused",
    currentData: { [type: string]: number | null }
}) {
    const [areaWidth, changeAreaWidth] = useState<number | null>(null);
    const [areaHeight, changeAreaHeight] = useState<number | null>(null);
    const [movementSpeed, changeMovementSpeed] = useState<number | null>(null);
    const [boringSpeed, changeBoringSpeed] = useState<number | null>(null);
    const [initialSpeed, changeInitialSpeed] = useState<Array<number | null> | null>(null);
    const [waybackSpeed, changeWaybackSpeed] = useState<Array<number | null> | null>(null);
    const [downDistance, changeDownDistance] = useState<number | null>(null);
    const [zTool, changeZTool] = useState<number | null>(null);
    const [discStartAngle, changeDiscStartAngle] = useState<number | null>(null);
    const [millToCamera, changeMillToCamera] = useState<Array<number | null> | null>(null);
    const [horizontalMargin, changeHorizontalMargin] = useState<Array<number | null> | null>(null);
    const [verticalMargin, changeVerticalMargin] = useState<Array<number | null> | null>(null);
    const [toolWearDepth, changeToolWearDepth] = useState<number | null>(null);
    const [toolWearWidth, changeToolWearWidth] = useState<number | null>(null);
    const [startLayer, changeStartLayer] = useState<number | null>(null);
    const [endLayer, changeEndLayer] = useState<number | null>(null);

    useEffect(() => {
        if (props.currentData === null) {
            return;
        }
        if (areaWidth == null || props.running === "running") {
            changeAreaWidth(props.currentData["area_width"]);
        }

        if (areaHeight == null || props.running === "running") {
            changeAreaHeight(props.currentData["area_height"]);
        }

        if (movementSpeed == null || props.running === "running") {
            changeMovementSpeed(props.currentData["movement_speed"]);
        }

        if (boringSpeed == null || props.running === "running") {
            changeBoringSpeed(props.currentData["boring_down_speed"]);
        }

        if (initialSpeed === null || props.running === "running") {
            changeInitialSpeed([
                props.currentData["drilling_initial_speed_default"],
                props.currentData["drilling_initial_speed_now"],
                props.currentData["drilling_initial_speed_max"],
            ]);
        }

        if (waybackSpeed === null || props.running === "running") {
            changeWaybackSpeed([
                props.currentData["drilling_wayback_speed_default"],
                props.currentData["drilling_wayback_speed_now"],
                props.currentData["drilling_wayback_speed_max"],
            ]);
        }

        if (downDistance === null || props.running === "running") {
            changeDownDistance(props.currentData["move_z_down_milling"]);
        }

        if (zTool === null || props.running === "running") {
            changeZTool(props.currentData["z_tool"]);
        }

        if (discStartAngle === null || props.running === "running") {
            changeDiscStartAngle(props.currentData["disc_start_angle"]);
        }

        if (millToCamera === null || props.running === "running") {
            changeMillToCamera([
                props.currentData["camera_to_mill_x"],
                props.currentData["camera_to_mill_y"]
            ]);
        }

        if (horizontalMargin === null || props.running === "running") {
            changeHorizontalMargin([
                props.currentData["horizontal_margin_x"],
                props.currentData["horizontal_margin_y"]
            ]);
        }

        if (verticalMargin === null || props.running === "running") {
            changeVerticalMargin([
                props.currentData["vertical_margin_x"],
                props.currentData["vertical_margin_y"]
            ]);
        }

        if (toolWearDepth === null || props.running === "running") {
            changeToolWearDepth(props.currentData["tool_wear_depth"]);
        }

        if (toolWearWidth === null || props.running === "running") {
            changeToolWearWidth(props.currentData["tool_wear_width"]);
        }

        if (startLayer === null || props.running === "running") {
            changeStartLayer(props.currentData["start_layer"]);
        }

        if (endLayer === null || props.running === "running") {
            changeEndLayer(props.currentData["end_layer"]);
        }

    }, [
        props.running,
        props.currentData,
        areaWidth,
        areaHeight,
        movementSpeed,
        boringSpeed,
        initialSpeed,
        waybackSpeed,
        downDistance,
        zTool,
        discStartAngle,
        millToCamera,
        horizontalMargin,
        verticalMargin,
        toolWearDepth,
        toolWearWidth,
        startLayer,
        endLayer
    ]);

    const checkUpdated = () => {
        return (
            props.currentData !== null &&
            props.currentData["area_width"] === areaWidth &&
            props.currentData["area_height"] === areaHeight &&
            props.currentData["movement_speed"] === movementSpeed &&
            props.currentData["boring_down_speed"] === boringSpeed &&
            (
                initialSpeed === null || (
                    props.currentData["drilling_initial_speed_default"] === initialSpeed[0] &&
                    props.currentData["drilling_initial_speed_now"] === initialSpeed[1] &&
                    props.currentData["drilling_initial_speed_max"] === initialSpeed[2]
                )
            ) &&
            (
                waybackSpeed === null || (
                    props.currentData["drilling_wayback_speed_default"] === waybackSpeed[0] &&
                    props.currentData["drilling_wayback_speed_now"] === waybackSpeed[1] &&
                    props.currentData["drilling_wayback_speed_max"] === waybackSpeed[2]
                )
            ) &&
            props.currentData["move_z_down_milling"] === downDistance &&
            props.currentData["z_tool"] === zTool &&
            props.currentData["disc_start_angle"] === discStartAngle &&
            (
                millToCamera === null || (
                    props.currentData["camera_to_mill_x"] === millToCamera[0] &&
                    props.currentData["camera_to_mill_y"] === millToCamera[1]
                )
            ) &&
            (
                horizontalMargin === null || (
                    props.currentData["horizontal_margin_x"] === horizontalMargin[0] &&
                    props.currentData["horizontal_margin_y"] === horizontalMargin[1]
                )
            ) &&
            (
                verticalMargin === null || (
                    props.currentData["vertical_margin_x"] === verticalMargin[0] &&
                    props.currentData["vertical_margin_y"] === verticalMargin[1]
                )
            ) &&
            props.currentData["tool_wear_depth"] === toolWearDepth &&
            props.currentData["tool_wear_width"] === toolWearWidth &&
            props.currentData["start_layer"] === startLayer &&
            props.currentData["end_layer"] === endLayer
        )
    }

    const sendCommand = () => {
        if (initialSpeed === null || waybackSpeed === null || millToCamera === null || horizontalMargin === null || verticalMargin === null) {
            return;
        }

        if (props.socket === null) {
            return;
        }

        const safety = (e: number | null): undefined | number => {
            if (e === null) {
                return undefined;
            }

            if (isNaN(e)) {
                return undefined;
            }

            return e;
        }

        let params = {
            "area_width": safety(areaWidth),
            "area_height": safety(areaHeight),
            "initial_default": safety(initialSpeed[0]),
            "initial_current": safety(initialSpeed[1]),
            "initial_max": safety(initialSpeed[2]),
            "wayback_default": safety(waybackSpeed[0]),
            "wayback_current": safety(waybackSpeed[1]),
            "wayback_max": safety(waybackSpeed[2]),
            "movement_speed": safety(movementSpeed),
            "boring_speed": safety(boringSpeed),
            "down_distance": safety(downDistance),
            "z_tool": safety(zTool),
            "mill_center_x": safety(millToCamera[0]),
            "mill_center_y": safety(millToCamera[1]),
            "disc_start_angle": safety(discStartAngle),
            "horizontal_margin_x": safety(horizontalMargin[0]),
            "horizontal_margin_y": safety(horizontalMargin[1]),
            "vertical_margin_x": safety(verticalMargin[0]),
            "vertical_margin_y": safety(verticalMargin[1]),
            "tool_wear_depth": safety(toolWearDepth),
            "tool_wear_width": safety(toolWearWidth),
            "start_layer": safety(startLayer),
            "end_layer": safety(endLayer)
        }

        let command = {
            method: "command",
            data: {
                command: "milling_params",
                params: params
            }
        }

        props.socket.send(JSON.stringify(command));
    }

    return (
        <Paper elevation={4} sx={{marginTop: 2, width: "95%"}}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="log-content"
                    id="log-header"
                >
                    <Typography variant="h6">Milling setup</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container sx={{width: "100%"}} spacing={{xs: 1, md: 3}}>
                        <Grid item xs={6} key="updated_status">
                            {checkUpdated() && <Typography variant="body1"
                                                           sx={{height: "100%", alignItems: "center", display: "flex"}}>All
                                data updated 🟢</Typography>}
                            {!checkUpdated() && <Typography variant="body1" sx={{
                                height: "100%",
                                alignItems: "center",
                                display: "flex"
                            }}>Changed data 🟡</Typography>}
                        </Grid>
                        <Grid item xs={6} key="update_button">
                            <Button
                                variant="text"
                                startIcon={<RestorePageIcon/>}
                                disabled={checkUpdated() && props.running !== "running"}
                                onClick={() => sendCommand()}>
                                Refresh
                            </Button>
                        </Grid>
                        <Grid item xs={12} key="area_size">
                            <Typography variant="body1" sx={{fontWeight: "bold"}}>Area size</Typography>
                        </Grid>
                        <Grid item xs={6} key="area_width_input">
                            <ParamField
                                name="Area width"
                                value={areaWidth}
                                changeValue={changeAreaWidth}
                                disable={props.running === "running"}
                                currentData={props.currentData === null ? null : props.currentData["area_width"]}
                            />
                        </Grid>
                        <Grid item xs={6} key="area_height_input">
                            <ParamField
                                name="Area height"
                                value={areaHeight}
                                changeValue={changeAreaHeight}
                                disable={props.running === "running"}
                                currentData={props.currentData === null ? null : props.currentData["area_height"]}
                            />
                        </Grid>
                        <Grid item xs={6} key="start_layer_input">
                            <ParamField
                                name="Start layer"
                                value={startLayer}
                                changeValue={changeStartLayer}
                                disable={props.running === "running"}
                                currentData={props.currentData === null ? null : props.currentData["start_layer"]}
                            />
                        </Grid>
                        <Grid item xs={6} key="end_layer_input">
                            <ParamField
                                name="End layer"
                                value={endLayer}
                                changeValue={changeEndLayer}
                                disable={props.running === "running"}
                                currentData={props.currentData === null ? null : props.currentData["end_layer"]}
                            />
                        </Grid>
                        <Grid item xs={12} key="drilling">
                            <Typography variant="body1" sx={{fontWeight: "bold"}}>Drilling</Typography>
                        </Grid>
                        <Grid item xs={6} key="depth_input">
                            <ParamField
                                name="Cut depth"
                                disable={props.running === "running"}
                                changeValue={changeDownDistance}
                                value={downDistance}
                                helperText=" (mm, + deeper, - shallower)"
                                currentData={props.currentData === null ? null : props.currentData["move_z_down_milling"]}
                                negativeEnabled={true}
                            />
                        </Grid>
                        <Grid item xs={6} key="z_tool_input">
                            <ParamField
                                name="Tool from wall"
                                disable={props.running === "running"}
                                changeValue={changeZTool}
                                value={zTool}
                                helperText=" (mm, + further, - closer)"
                                currentData={props.currentData === null ? null : props.currentData["z_tool"]}
                            />
                        </Grid>
                        <Grid item xs={6} key="angle_input">
                            <ParamField
                                name="Angle"
                                disable={props.running === "running"}
                                changeValue={changeDiscStartAngle}
                                value={discStartAngle}
                                precision={1}
                                helperText=" (°, +↺, -↻)"
                                currentData={props.currentData === null ? null : props.currentData["disc_start_angle"]}
                                negativeEnabled={true}
                            />
                        </Grid>
                        <Grid item xs={6} key="placeholder">
                        </Grid>
                        <Grid item xs={6} key="tool_wear_width">
                            <ParamField
                                name="Tool wear width"
                                disable={props.running === "running"}
                                changeValue={changeToolWearWidth}
                                value={toolWearWidth}
                                precision={1}
                                currentData={props.currentData === null ? null : props.currentData["tool_wear_width"]}
                                negativeEnabled={true}
                            />
                        </Grid>
                        <Grid item xs={6} key="tool_wear_depth">
                            <ParamField
                                name="Tool wear depth"
                                disable={props.running === "running"}
                                changeValue={changeToolWearDepth}
                                value={toolWearDepth}
                                precision={1}
                                currentData={props.currentData === null ? null : props.currentData["tool_wear_depth"]}
                                negativeEnabled={true}
                            />
                        </Grid>
                        <Grid item xs={6} key="horizontal_margin_x">
                            <ParamField
                                name="Horizontal margin X"
                                disable={props.running === "running"}
                                changeValue={(e: number) => {
                                    if (horizontalMargin !== null) {
                                        changeHorizontalMargin([e, horizontalMargin[1]]);
                                    }
                                }}
                                value={horizontalMargin == null ? null : horizontalMargin[0]}
                                precision={1}
                                currentData={props.currentData === null ? null : props.currentData["horizontal_margin_x"]}
                                negativeEnabled={true}
                                helperText={" (mm, + further, - closer)"}
                            />
                        </Grid>
                        <Grid item xs={6} key="horizontal_margin_y">
                            <ParamField
                                name="Horizontal margin Y"
                                disable={props.running === "running"}
                                changeValue={(e: number) => {
                                    if (horizontalMargin !== null) {
                                        changeHorizontalMargin([horizontalMargin[0], e]);
                                    }
                                }}
                                value={horizontalMargin == null ? null : horizontalMargin[1]}
                                precision={1}
                                currentData={props.currentData === null ? null : props.currentData["horizontal_margin_y"]}
                                negativeEnabled={true}
                                helperText={" (mm, + further, - closer)"}
                            />
                        </Grid>
                        <Grid item xs={6} key="vertical_margin_x">
                            <ParamField
                                name="Vertical margin X"
                                disable={props.running === "running"}
                                changeValue={(e: number) => {
                                    if (verticalMargin !== null) {
                                        changeVerticalMargin([e, verticalMargin[1]]);
                                    }
                                }}
                                value={verticalMargin == null ? null : verticalMargin[0]}
                                precision={1}
                                currentData={props.currentData === null ? null : props.currentData["vertical_margin_x"]}
                                negativeEnabled={true}
                                helperText={" (mm, + further, - closer)"}
                            />
                        </Grid>
                        <Grid item xs={6} key="vertical_margin_y">
                            <ParamField
                                name="Vertical margin Y"
                                disable={props.running === "running"}
                                changeValue={(e: number) => {
                                    if (verticalMargin !== null) {
                                        changeVerticalMargin([verticalMargin[0], e]);
                                    }
                                }}
                                value={verticalMargin == null ? null : verticalMargin[1]}
                                precision={1}
                                currentData={props.currentData === null ? null : props.currentData["vertical_margin_y"]}
                                negativeEnabled={true}
                                helperText={" (mm, + further, - closer)"}
                            />
                        </Grid>
                        <Grid item xs={12} key="speed">
                            <Typography variant="body1" sx={{fontWeight: "bold"}}>Speed</Typography>
                        </Grid>
                        <Grid item xs={6} key="movement_speed_input">
                            <ParamField
                                disable={props.running === "running"}
                                name="Movement speed"
                                changeValue={changeMovementSpeed}
                                value={movementSpeed}
                                currentData={props.currentData === null ? null : props.currentData["movement_speed"]}
                            />
                        </Grid>
                        <Grid item xs={6} key="boring_speed_input">
                            <ParamField
                                disable={props.running === "running"}
                                name="Boring speed"
                                changeValue={changeBoringSpeed}
                                value={boringSpeed}
                                currentData={props.currentData === null ? null : props.currentData["boring_down_speed"]}
                            />
                        </Grid>
                        <Grid item xs={4} key="initial_default_speed_input">
                            <ParamField
                                disable={props.running === "running"}
                                name="Initial default"
                                changeValue={(e: number) => {
                                    if (initialSpeed !== null) {
                                        changeInitialSpeed([e, initialSpeed[1], initialSpeed[2]])
                                    }
                                }}
                                value={initialSpeed == null ? null : initialSpeed[0]}
                                currentData={props.currentData === null ? null : props.currentData["drilling_initial_speed_default"]}
                            />
                        </Grid>
                        <Grid item xs={4} key="initial_current_speed_input">
                            <ParamField
                                disable={props.running === "running"}
                                name="Initial current"
                                changeValue={(e: number) => {
                                    if (initialSpeed !== null) {
                                        changeInitialSpeed([initialSpeed[0], e, initialSpeed[2]])
                                    }
                                }}
                                value={initialSpeed == null ? null : initialSpeed[1]}
                                currentData={props.currentData === null ? null : props.currentData["drilling_initial_speed_now"]}
                            />
                        </Grid>
                        <Grid item xs={4} key="initial_max_speed_input">
                            <ParamField
                                disable={props.running === "running"}
                                name="Initial max"
                                changeValue={(e: number) => {
                                    if (initialSpeed !== null) {
                                        changeInitialSpeed([initialSpeed[0], initialSpeed[1], e])
                                    }
                                }}
                                value={initialSpeed == null ? null : initialSpeed[2]}
                                currentData={props.currentData === null ? null : props.currentData["drilling_initial_speed_max"]}
                            />
                        </Grid>
                        <Grid item xs={4} key="wayback_default_speed_input">
                            <ParamField
                                disable={props.running === "running"}
                                name="Wayback default"
                                changeValue={(e: number) => {
                                    if (waybackSpeed !== null) {
                                        changeWaybackSpeed([e, waybackSpeed[1], waybackSpeed[2]])
                                    }
                                }}
                                value={waybackSpeed == null ? null : waybackSpeed[0]}
                                currentData={props.currentData === null ? null : props.currentData["drilling_wayback_speed_default"]}
                            />
                        </Grid>
                        <Grid item xs={4} key="wayback_current_speed_input">
                            <ParamField
                                disable={props.running === "running"}
                                name="Wayback current"
                                changeValue={(e: number) => {
                                    if (waybackSpeed !== null) {
                                        changeWaybackSpeed([waybackSpeed[0], e, waybackSpeed[2]])
                                    }
                                }}
                                value={waybackSpeed == null ? null : waybackSpeed[1]}
                                currentData={props.currentData === null ? null : props.currentData["drilling_wayback_speed_now"]}
                            />
                        </Grid>
                        <Grid item xs={4} key="wayback_max_speed_input">
                            <ParamField
                                disable={props.running === "running"}
                                name="Wayback max"
                                changeValue={(e: number) => {
                                    if (waybackSpeed !== null) {
                                        changeWaybackSpeed([waybackSpeed[0], waybackSpeed[1], e])
                                    }
                                }}
                                value={waybackSpeed == null ? null : waybackSpeed[2]}
                                currentData={props.currentData === null ? null : props.currentData["drilling_wayback_speed_max"]}
                            />
                        </Grid>
                        <Grid item xs={12} key="other">
                            <Typography variant="body1" sx={{fontWeight: "bold"}}>Other</Typography>
                        </Grid>
                        <Grid item xs={6} key="mill_to_cam_x_input">
                            <ParamField
                                name="Mill to camera X"
                                disable={props.running === "running"}
                                changeValue={(e: number) => {
                                    if (millToCamera !== null) {
                                        changeMillToCamera([e, millToCamera[1]]);
                                    }
                                }}
                                value={millToCamera == null ? null : millToCamera[0]}
                                precision={1}
                                helperText=" (mm, + left, - right)"
                                currentData={props.currentData === null ? null : props.currentData["camera_to_mill_x"]}
                                negativeEnabled={true}
                            />
                        </Grid>
                        <Grid item xs={6} key="mill_to_cam_y_input">
                            <ParamField
                                name="Mill to camera Y"
                                disable={props.running === "running"}
                                changeValue={(e: number) => {
                                    if (millToCamera !== null) {
                                        changeMillToCamera([millToCamera[0], e]);
                                    }
                                }}
                                value={millToCamera == null ? null : millToCamera[1]}
                                precision={1}
                                helperText=" (mm, + up, - down)"
                                currentData={props.currentData === null ? null : props.currentData["camera_to_mill_y"]}
                                negativeEnabled={true}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Paper>
    );
}