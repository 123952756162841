import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    Paper,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as React from "react";


export default function EnabledImages(props: { enabled: Array<string>, onChange: (name: string) => void }) {
    const all = [
        "mortar",
        "cand",
        "depth_rgb",
        "frame_contours",
        "frame_mask",
        "visible_contours",
        "depth_contours",
        "rv",
        "tuna",
        "combined",
        "otsu",
        "masked",
        "depth_otsu",
        "mortar_filtered",
        "depth_proc",
        "rects",
        "left",
        "right"
    ]
    const renderItem = (name: string, value: boolean) => {
        return (
            <FormControlLabel
                key={name}
                control={
                    <Checkbox
                        checked={value}
                        id={name}
                        onChange={() => props.onChange(name)}
                    />
                }
                label={
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="body1">{name}</Typography>
                    </Box>
                }
            />
        );
    }

    return (
        <Paper elevation={4} sx={{width: "95%"}}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="run-images-content"
                    id="run-images-header"
                >
                    <Typography variant="h6">Enabled images</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FormGroup>
                        <Grid container spacing={1}>
                            {all.map(name => (
                                <Grid item xs={4}
                                      key={name}>{renderItem(name, (props.enabled.indexOf(name) !== -1))}</Grid>))}
                        </Grid>
                    </FormGroup>
                </AccordionDetails>
            </Accordion>
        </Paper>
    );
}