import {FormControl, InputLabel, MenuItem, Select, Stack, Typography} from "@mui/material";
import * as React from "react";
import {useEffect} from "react";

export default function EndpointList(props: {
    robots: Array<string>,
    chosenRobot: string | undefined,
    notifyChange(robot: string): void
}) {

    useEffect(() => {
        if(props.chosenRobot !== undefined) {
            props.notifyChange(props.chosenRobot);
        }
    }, [props.chosenRobot])

    const handleChange = (v: any) => {
        window.location.replace("/robots/" + v.props.value.toLowerCase());
    }

    const renderRobot = (e: string) => {
        const decoration = e === props.chosenRobot ? "underline" : "none";

        return (
            <MenuItem key={e} value={e}>
                <Stack direction="column">
                    <Typography component="div" variant="body1"
                                sx={{fontWeight: "bold", textDecoration: decoration}}>{e}</Typography>
                </Stack>
            </MenuItem>);
    }


    return (
        <FormControl sx={{width: "65%"}}>
            <InputLabel id="endpoint-select-label">Robot</InputLabel>
            <Select
                labelId="endpoint-select-label"
                id="endpoint-select"
                value={props.chosenRobot}
                label="Endpoint"
                onChange={(e, v) => handleChange(v)}
            >
                {props.robots.length > 0 ? props.robots.map(e => renderRobot(e)) :
                    <MenuItem disabled>
                        <Typography>No robot to connect to</Typography>
                    </MenuItem>}
            </Select>
        </FormControl>
    );

}