import * as React from "react";
import {useEffect, useState} from "react";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "../utils/firebase";
import UnsignedLayout from "../components/UnsignedLayout";
import SignedHeader from "../components/SignedHeader";
import logo from "../images/logo.png";
import {Box, Button, Drawer, Stack, Typography} from "@mui/material";
import SideDrawer from "../components/SideDrawer";
import ExecutionButtonGroup from "../components/control/ExecutionButtonGroup";
import Status from "../components/control/Status";
import ImageGroup from "../components/control/ImageGroup";
import EndpointList from "../components/control/EndpointList";
import DataDisplay from "../components/control/DataDisplay";
import MillingSetup from "../components/control/MillingSetup";
import BrickView from "../components/control/BrickView";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useParams} from "react-router-dom";
import EnabledImages from "../components/control/EnabledImages";

export default function RobotsPage(props: { name: string, apiUrl: string }) {
    const maxFrames = 30;

    const {robotName} = useParams();

    const serverUrl = props.apiUrl.replace("/^http/", "ws") + "/frontend";
    const [serverRunning, changeServerRunning] = useState(false);
    const [serverLastSeen, updateLastSeen] = useState<Date | null>(null);

    const [drawerOpened, openDrawer] = useState(false);
    const [userName, changeUserName] = useState("");
    const [robotRunning, updateRobotRunning] = useState<"stopped" | "running" | "paused">("stopped");

    const [status, updateStatus] = useState<{
        [datetime: number]: { datetime: number, stopped: number, data: any, wall: any }
    }>({});
    const [images, updateImages] = useState<{ [datetime: number]: { [imageType: string]: string } }>({})

    const [statusTimestamp, changeStatusTimestamp] = useState<number | null>(null);

    const [enabledImageTypes, changeEnabledImageTypes] = useState<Array<string>>([]);
    const [robots, changeRobots] = useState<Array<string>>([]);
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const setupSocket = (ws: WebSocket): WebSocket => {
        ws.addEventListener('open', function (event) {
            let bindMessage = {
                method: "bind",
                data: {
                    name: robotName
                }
            }
            changeServerRunning(true)
            if (robotName !== undefined) {
                ws.send(JSON.stringify(bindMessage))

                let resendMessage = {
                    method: "command",
                    data: {
                        command: "resend",
                        params: {
                            count: 10
                        }
                    }
                }
                setTimeout(function () {
                    ws.send(JSON.stringify(resendMessage));
                }, 300);
            }
        })

        ws.addEventListener('message', function (event) {
            let parsed: { method: string, name: string, data: any } = JSON.parse(event.data)
            console.log(parsed);
            updateLastSeen(new Date());

            if (parsed.method === "status") {
                let data = parsed.data as {
                    datetime: number,
                    stopped: number,
                    data: any,
                    wall: any,
                    image_paths: { [imageType: string]: string }
                }

                updateImages(oldData => {
                    let newData = sortObjectByNumberKeys({
                        ...oldData,
                        [data.datetime]: {
                            ...(oldData[data.datetime] || {}),
                            ...data.image_paths
                        }
                    });

                    if (Object.keys(newData).length > maxFrames) {
                        let keys = Object.keys(newData).map(key => Number(key));
                        for (let i = 0; i < keys.length - maxFrames; i++) {
                            delete newData[keys[i]];
                        }
                    }

                    return newData;
                })

                delete data.data.image_paths;

                updateStatus(oldData => {
                    let newData = sortObjectByNumberKeys({
                        ...oldData,
                        [data.datetime]: {
                            datetime: data.datetime,
                            stopped: data.stopped,
                            data: data.data,
                            wall: data.wall
                        }
                    })

                    if (Object.keys(newData).length > maxFrames) {
                        let keys = Object.keys(newData).map(key => Number(key));
                        for (let i = 0; i < keys.length - maxFrames; i++) {
                            delete newData[keys[i]];
                        }
                    }

                    let newestEntry = newData[Number(Object.keys(newData)[Object.keys(newData).length - 1])];
                    let oldNewestEntry = oldData[Number(Object.keys(oldData)[Object.keys(oldData).length - 1])];

                    let newRobotRunning: "running" | "paused" | "stopped";
                    newRobotRunning = "running";

                    if (newestEntry.stopped === 1) {
                        newRobotRunning = "paused";
                    } else if (newestEntry.stopped === -1) {
                        newRobotRunning = "stopped";
                    }
                    updateRobotRunning(newRobotRunning);

                    changeStatusTimestamp((timestamp) => {
                        if (newRobotRunning === "running" || Object.keys(oldData).length === 0 || timestamp === oldNewestEntry.datetime) {
                            return newestEntry.datetime;
                        } else {
                            return timestamp;
                        }
                    });

                    return newData;
                })
            } else if (parsed.method === "overview") {
                if (parsed.data.length === 0) {
                    changeRobots([]);
                } else {
                    changeRobots(parsed.data)
                }
            }
        })

        ws.addEventListener('close', function (event) {
            console.log('Server closed connection: ', event);
            changeServerRunning(false);
            setTimeout(function () {
                setSocket(setupSocket(new WebSocket(serverUrl)));
            }, 100);
        });

        ws.addEventListener('error', function (event) {
            console.log('Error: ', event);
        });

        return ws;
    }

    const connectToServer = () => {
        if (socket !== null) {
            if (socket.readyState !== WebSocket.OPEN) {
                setTimeout(function () {
                    console.log("Reconnecting to server");
                    connectToServer();
                }, 200);
            } else {
                socket.send(JSON.stringify({method: "overview", data: {}}));
            }
        }
    }

    useEffect(() => {
        connectToServer();
    }, [socket]);

    useEffect(() => {
        const ws = new WebSocket(serverUrl);
        setSocket(setupSocket(ws));

        return () => {
            if (socket !== null) {
                socket.close()
            }
        };
    }, [serverUrl]);

    function sortObjectByNumberKeys(obj: { [keys: number]: any }): { [keys: number]: any } {
        const sortedKeys = Object.keys(obj)
            .filter(key => !isNaN(Number(key)))
            .sort((a, b) => Number(a) - Number(b));

        let sortedObj: { [keys: number]: any } = {};
        for (let key of sortedKeys) {
            sortedObj[Number(key)] = obj[Number(key)];
        }
        return sortedObj;
    }

    const checkKey = (e: KeyboardEvent) => {
        let sortedTimestamps = Object.keys(status)
        if (sortedTimestamps.length < 1 || robotRunning === "running" || statusTimestamp === null) {
            return;
        }
        if (e.code === "ArrowRight" && sortedTimestamps.indexOf(statusTimestamp.toString()) < sortedTimestamps.length - 1) {
            changeStatusTimestamp(Number(sortedTimestamps[sortedTimestamps.indexOf(statusTimestamp.toString()) + 1]))
        } else if (e.code === "ArrowLeft" && sortedTimestamps.indexOf(statusTimestamp.toString()) > 0) {
            changeStatusTimestamp(Number(sortedTimestamps[sortedTimestamps.indexOf(statusTimestamp.toString()) - 1]))
        }
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user && auth.currentUser) {
                let name = auth.currentUser.displayName;
                if (name === undefined || name === null) {
                    name = auth.currentUser.email!.split("@")[0];
                }
                changeUserName(name);
            } else {
                window.location.replace('/');
            }
        });
    }, []);

    useEffect(() => {
        document.onkeydown = (event: KeyboardEvent) => checkKey(event);
    }, [statusTimestamp, robotRunning, status]);

    const robotChanged = (newRobotName: string) => {
        if (socket !== null) {
            console.log(socket);
            console.log(socket.readyState);

            if (socket.readyState !== WebSocket.OPEN) {
                setTimeout(function () {
                    console.log("Trying in a while");
                    robotChanged(newRobotName);
                }, 200);
                return;
            }

            console.log("Binding to " + newRobotName);


            let bindMessage = {
                method: "bind",
                data: {
                    name: newRobotName
                }
            }

            socket.send(JSON.stringify(bindMessage))
            updateStatus({});
            updateImages({});
            changeStatusTimestamp(null);

            let resendMessage = {
                method: "command",
                data: {
                    command: "resend",
                    params: {
                        count: 10
                    }
                }
            }
            setTimeout(function () {
                socket.send(JSON.stringify(resendMessage));
            }, 300);
        }
    }

    const enableImageType = (type: string) => {
        let disable = (enabledImageTypes.indexOf(type) !== -1)
        if (socket !== null) {
            let message = {
                method: "command",
                data: {
                    command: "enable_image",
                    params: {
                        image_type: type,
                        disable: disable
                    }
                }
            }
            socket.send(JSON.stringify(message));

            if (disable) {
                let newEnabledImageTypes = enabledImageTypes.filter((value, index, arr) => {
                    return value !== type;
                });
                changeEnabledImageTypes(newEnabledImageTypes);
            } else {
                changeEnabledImageTypes([...enabledImageTypes, type]);
            }
        }
    }

    return userName === "" ? <UnsignedLayout/> :
        (robotName === undefined ? (
            <Box sx={{display: "flex", flexFlow: "column", height: "100%"}}>
                <SignedHeader
                    name={props.name}
                    icon={logo}
                    openDrawer={() => openDrawer(true)}/>
                <Stack sx={{
                    width: '100%',
                    typography: 'body1',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                       direction="column">
                    <Typography variant="h4" sx={{padding: 2}}>
                        Please choose a robot to connect to
                    </Typography>
                    <EndpointList robots={robots} chosenRobot={robotName}
                                  notifyChange={robotChanged}/>
                </Stack>
            </Box>) : (
            <Box sx={{display: "flex", flexFlow: "column", height: "100%"}}>
                <SignedHeader
                    name={props.name}
                    icon={logo}
                    openDrawer={() => openDrawer(true)}/>
                <Box sx={{
                    width: '100%',
                    typography: 'body1',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "45%",
                        width: "45%",
                        height: "700px",
                        margin: 1,
                        alignItems: "center"
                    }}>
                        <Stack
                            sx={{
                                height: "5%",
                                width: "100%",
                                paddingBottom: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                            direction="row">
                            <Button
                                variant="outlined"
                                startIcon={<ArrowBackIcon/>}
                                disabled={robotRunning === "running"}
                                sx={{height: "100%", width: "30%"}}
                                onClick={() => (statusTimestamp === null || statusTimestamp === Number(Object.keys(status)[0])) ? null : changeStatusTimestamp(Number(Object.keys(status)[Object.keys(status).indexOf(statusTimestamp.toString()) - 1]))}
                            >
                                Prev
                            </Button>
                            <Typography
                                sx={{
                                    height: "100%",
                                    width: "40%",
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center"
                                }}
                            >
                                {(Object.keys(status).length < 1 || statusTimestamp === null) ? "No history" : ((Object.keys(status).indexOf(statusTimestamp.toString()) + 1) + "/" + (Object.keys(status).length))}
                            </Typography>
                            <Button
                                variant="outlined"
                                endIcon={<ArrowForwardIcon/>}
                                disabled={robotRunning === "running"}
                                onClick={() => (statusTimestamp === null || statusTimestamp === Number(Object.keys(status)[Object.keys(status).length - 1])) ? null : changeStatusTimestamp(Number(Object.keys(status)[Object.keys(status).indexOf(statusTimestamp.toString()) + 1]))}
                                sx={{height: "100%", width: "30%"}}
                            >
                                Next
                            </Button>
                        </Stack>
                        <ImageGroup
                            socket={socket}
                            availableBricks={(Object.keys(status).length < 1 || statusTimestamp === null) ? undefined : (status[statusTimestamp] === undefined ? undefined : status[statusTimestamp].wall.map((brick: any) => brick.id))}
                            enableManualBricks={robotRunning === "paused" && statusTimestamp !== null && Object.keys(status).length > 0 && Object.keys(status).indexOf(statusTimestamp.toString()) === Object.keys(status).length - 1}
                            availableImages={(Object.keys(images).length < 1 || statusTimestamp === null) ? undefined : (images[statusTimestamp] === undefined ? undefined : images[statusTimestamp])}/>
                    </Box>
                    <ExecutionButtonGroup socket={socket} execution={robotRunning}/>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "45%",
                        height: "700px",
                        margin: 1,
                        alignItems: "center"
                    }}>
                        <Stack direction="row" spacing={3} sx={{alignItems: "center", justifyContent: "center"}}>
                            <Status lastSeen={serverLastSeen} running={serverRunning}/>
                            <EndpointList robots={robots} chosenRobot={robotName}
                                          notifyChange={robotChanged}/>
                        </Stack>
                        <Stack direction="column" spacing={0.5}
                               sx={{overflow: "auto", overflowY: "scroll", maxHeight: "700px", padding: 1}}>
                            <EnabledImages enabled={enabledImageTypes} onChange={enableImageType}/>
                            <DataDisplay data={status} index={statusTimestamp}/>
                            <MillingSetup running={robotRunning}
                                          currentData={(statusTimestamp === null || Object.keys(status).length < 1) ? null : status[statusTimestamp].data}
                                          socket={socket}/>
                            <BrickView
                                workingBrick={(statusTimestamp === null || Object.keys(status).length < 1) ? null : status[statusTimestamp].data["current_brick"]}
                                socket={socket}
                                wall={(statusTimestamp === null || Object.keys(status).length < 1) ? null : status[statusTimestamp].wall}/>
                        </Stack>
                    </Box>
                </Box>
                <Drawer
                    anchor="left"
                    open={drawerOpened}
                    onClose={() => openDrawer(false)}
                >
                    <SideDrawer
                        closeDrawer={() => openDrawer(false)}
                        user={userName}
                    />
                </Drawer>
            </Box>
        ));
}